@page {
  size: 8.5in 11in;
}

.optimizerpdfview{
  text-align: center;
  

  .pdf_page{
  }

  .row{
    margin: 0;
    padding: 0;
  }

  .pdf_footer{
    width: 100%;
    height: 15vh;
    background-color: #333E6B;
    margin: 0 !important;
    padding: 0 !important;
    position: fixed;
    bottom: 0;

    .page{
      color: #fff;
      font-size: 1.55em !important;
      font-weight: bold !important;
      text-decoration: none;
    }
    .unlocking{

      border-right: 1px solid #fff;
      justify-content: center;
      width: 50% !important;
      height: 11vh;
      margin-top: 2vh;

      img{
        height: 6.5vh;
        width: auto;
        margin-bottom: 1vh;
        margin-top: 0vh;
      }
    }

    .abl{
      justify-content: center;
      width: 50% !important;
      margin-top: 2vh;
      height: 11vh;
      padding-left: 15%;
      padding-right: 15%;

      img{
        height: 4.5vh;
        width: auto;
        margin-bottom: 2vh;
        margin-top: 1vh;
      }
    }
  }

  @media print {
    .page_break {
        page-break-after: always;
    }
  }

  .pdf_header{
    width: 100%;
    height: 15vh;
    background-color: #333E6B;
    color: #fff;

    .header_big_text{
      text-align: center;
      font-size: 2.5em !important;
      font-weight: bold;
      padding-top: 1.5vh !important;
      padding-bottom: 1.5vh !important;

    }

    .header_medium_text{
      padding-left: 2vw;
      text-align: left !important;
      font-size: 1.55em !important;
      font-weight: bold !important;

    }

    .header_small_text{
      padding-left: 2vw;
      font-size: 1.35em !important;
      text-align: left !important;
      font-weight: 450 !important;
    }
  }

  .board{
    margin: 0;
    display: flex;
    justify-content: center;

    .center_container{
      justify-content: right;
      width: 80%;


      .rows{

       
        .centeritem{
          position: relative;
          justify-content: center;

          .schedulerow{
            width: 100%;
       
            margin: 0px !important;

            
            .row{
              
              width: 100%;

              
              .classsizefte{
                .center{

                }
              }
              .classsizefte_text{
                margin: 0 !important;

                margin-top: 2.8vh !important;
                font-size: 2em !important;
                height: 5vh !important;

                padding-top: 20px  !important;
                padding-bottom: 7%!important;
                padding-left: 40%!important;
                padding-right: 40%!important;
                line-height: 1;
                
                
              }

              .classsize_fte_contents{
                transform: translateY(-2.5vh);

                img{
                  transform: scale(2);
                  padding-right: 0.5vw;
                  margin-bottom: 0.5vh;
                }
              }

              .numberdifference{
                font-size: 1.8em !important;

              }
              .noneselectednumber-properties{
                
                
                height: 5vh !important;
                font-size: 1.8em !important;
                padding-top: 1.3vh !important;
              }

              .selectednumber-properties{
                
                
                height: 5vh !important;
                font-size: 2.8em !important;
                padding-top: 0.3vh !important;

              }

              .teacher-student{
                border-right: 1px solid #979797 !important;

                width: 48%;
                height: 12vh !important;
                .row-title{
                  margin-top: 0.5vh;
                  font-size: 2rem !important;
                  
                }

                .percent_text{
                  font-size: 2rem !important;
                  padding-top: 0.2vh;
                  transform: translateX(-5%);
                }
                .percent_sub_text{
                  padding-top: 0.3vh;
                  font-size: 1.3rem !important;
                  transform: translateX(-5%);

                }

                

                .box{
                  margin-right: 0.15vh;
                  width: 1.5vw !important;
                  height: 1.5vw !important;
                }
              }

              

              .classsizefte{
                width: 26% !important;
                height: 10vh !important;
              }

              .delete_btn{
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  .w-23{
    width: 26% !important;
    
  }

  .fte_box{
    border-right: none !important;
    border-left: none !important;
  }

  .focus-middle{
    border-right: none !important;
    border-left: none !important;
    width: 26% !important;
  }

  .class_size_box{
    border-right: 1px solid #979797 !important;
  }

  .link-pointer{
    cursor: pointer;
  }
  
}



