.classsizefte{
  position: relative;
  display: flex;

  height:97px;
   

  .good{
    color: #56C31E;
    
  }

  .bad{
      color: #FF6633;
  }

  .selectednumber-properties{
    font-size: 22px;
    font-family: myYantramanav;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0;
  }

  .numberdifference{
    font-size: 16px;
    font-family: myYantramanav;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0;
    padding-left: 10px;
    color: #ADB2C4;
  }

  .noneselectednumber-properties{
    font-size: 16px;
    font-family: myYantramanav;
    font-weight: 500;
    padding-top: 5px; 
    margin: 0;
    color: #ADB2C4;
  }

  .centercomponent{
    justify-content: center;
    align-items: center;
    
    
  }

  .center{
    justify-content: center;
    align-self: center;
    padding: 0;
  }

  .classsizefte_text{
    font-size: 16px;
    font-family: myYantramanav;
    font-weight: 500;
    padding: 0;
    justify-content: center;
    color: #333E6B;
    transform: translateY(-20px);
    
  }

  

  @media print{
    transform: translateX(-35.5%);
  }

}


