.comparescheduledropdown{
  position: relative;
  width: fit-content;
  margin-left: 18px;

  .compare_dropdown{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #5BAAC8;
    font-size: 14px;
    font-style: italic;
    color: #fff;
    border-width: 1px;
    border-radius: 3px 3px 3px 3px;
    padding: 4px;
    padding-right: 35px !important;
    margin-top: 18px;
    width: 250px;
    white-space: nowrap;

    
  }

  .options_btn_active{
    border-radius: 3px 3px 0px 0px !important;

  }

  .compare_dropdown:after{
    position: absolute;
    content: "";
    transform: translateX(20px) translateY(10px);
    border: 4px solid transparent;
    border-color: #fff transparent transparent transparent;
    z-index: 0;
    pointer-events: none;
}

  .compare_dropdown:hover{
    background-color: rgb(81, 151, 179);

  }

  .options_container{
    margin-bottom: 10px;
    position: absolute;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    display: none;

    .schedule_option{
      padding: 5px;
      background-color: #fff;
      width: 250px;
      font-family: myYantramanav;
      border-left: 1px solid #979797;
      border-right: 1px solid #979797;
    }
    .schedule_option:hover{
      cursor: pointer;
      background-color: #EFEFEF;
      font-weight: bold;
    }
  }

  .options_container_active{
    display: block !important;
  }
  
}
