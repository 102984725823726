.schedulerow{
  position: relative;
  display: flex;
  
  width: fit-content;
  height: max-content;


  border-bottom: 1px solid #BFBFBF;
  
  .delete_btn{
    color: #4A4A4A;
    position: absolute;
    font-size: 15px;
    font-family: myRaleway;
    cursor: pointer;
    transform: scaleX(1.1);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;

  }
  .container {
    padding: 0px;
  }

}
