.informativebox{
  position: relative;
  display: flex;
  width:622px;
  height:290px;
  border:1px solid #BFBFBF;
  margin-bottom: 20px;
    

  
  .baseschedule-text-properties{
    position: relative;
    font-size: 15px;
    font-weight: bold;
    font-family: myRaleway;
    justify-content:center;
  }

  .left-text-properties{
    position: relative;
    font-family: myYantramanav;
    justify-content:center;
    align-self: center;
    padding-right: 10px;
  }

  .youhave-text-properties{
    position: relative;
    font-size: 16px; 
    justify-content:center;
    
  }
  .of-text-properties{
    position: relative;
    font-size: 16px; 
    justify-content:center;
    padding-left: 10px;
    padding-right: 10px;
    align-self: center;
    
  }

  .numbers-text-properties{
    position: relative;
    font-size: 22px;
    font-weight: bold;
    font-family: myRaleway;
    justify-content:center;
  }
  
  .centeritems{
    justify-content:center;
  }

  .right-text-properties{
    position: relative;
    font-family: myYantramanav;
    justify-content:center;
    margin:10px;
    padding-left: 0;
    padding-right: 0;
    max-width: max-content;
    max-width: 227px;
  }
  
  .darkerbluebackground{
    position: relative;
    max-height: 60px;
    background-color: #DEEDF3;
    justify-content:center;
    align-content: center;
    font-size: 12px;
    padding:15px;
  }

  .lighterbluebackground{
    position: relative;
    height: 210px;
    background-color: #EEF6F9;
    justify-content:center;
    align-content: center;
    padding:20px;
    text-align: left;
    font-size: 12px;
    max-width: 227px;
  }

}