.wizard{
  height: 100%;
  background-color: #fff;
  overflow-y: visible;
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  .centered_form{
    transform: translateX(50%) translateX(- 115px);
    padding-bottom: 10px;
    display: inline-block;
    text-align: left;
  }

  .clean_space{
    height: 10px;
  }

  .next_btn{
    min-width: 160px;
    max-width: 180px;
    font-size: 15px;
  }

  .back_btn{
    min-width: 160px;
    max-width: 180px;
    font-size: 15px;
  }

  .dropdown_form_entry{
    text-align: center;

  }
  .reduced-width {
    .form_entry{
      max-width: 200px;
    }
  }

  .text-align-center {
    font-size: 15px;
    text-align: center;
    max-width: 455px;
    align-self: center;
  }
  

  #dropdown_periodsrotate_id{
    .label{
      .tooltip-properties-dropdown{
          position: absolute ;
          z-index: 10 ;
          transform: translateX(204px) translateY(10px) !important;
      }
    }
  }

  .button_type_a{
    background-color: #5BAAC8;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    border-width: 1px;
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
  

  .stepone{
    .centered_form{
      padding-bottom: 79px;
      display: inline-block;
      
    }

    #error_teacher_student {
      position: absolute;
      color: #A11324;
      font-family: myRaleway;
      font-style: italic;
      font-size: 12px;
      pointer-events: none !important;
      max-width: 200px;
      font-weight: 500;
    }

    .page_change_buttons{
      transform: translateX(-20px);
    }
  }

  .steptwo{
    .informativeboxcomponent{
      position: relative; 
      display: flex;
      justify-content: center;
      padding-top: 65px;
    }
  }

  .stepthree{
    .final_text{
      padding: 0px !important;
      margin-bottom: 3px !important;
    }
    .align-text-left {
      .input_background{
        padding-left: 10px;
        text-align: left !important;
      }
    }
  }

  .toast-properties{
    font-size: 15px;
    text-align: justify;
    text-justify: inter-word;
  }
  
}