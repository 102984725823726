.comparison-header{
  display: flex;
  justify-content: center;

  .bold_number{
    font-weight: 700;
    font-size: 15px;
    margin: 0;
    padding: 0 3px 0 3px;
    transform: translateY(-1px);
  }
  .col {
    padding: 0px;
  }

  .mx-auto{
    width: 65vw;
    max-width: 65vw !important;
    min-width: 600px;
    padding: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .main_row {
    width: 65vw !important;
    max-width: 65vw !important;
    min-width: 600px;
    box-shadow: #858585 0px 2px 4px 0px;
  }

  .base_schedule{
    width: 100%;
    .schedulerow{
      width: 100%;
      .row{
        width: 100%;
        .delete_btn{
          display: none;
        }
      }
    }
  }
  
  .base-schedule {
    color: #333E6B;
    font-size: 16px;
    font-family: myYantramanav;
    font-weight: 500;
    padding-left: 30px !important;
    text-align: left;
    width: 54%;
    display: flex;
    padding-bottom: 5px;
  }

  .school-info{
    width: 100%;
    text-align: center;
    background-color: #FCF1EE;
    color: #E37358;
    font-weight: 500;
    font-size: 12px;
    font-family: myYantramanav;
    border-bottom: 1px solid #979797;

  }

  .school-students{
    padding-left: 30px;
    text-align: left;
    width: 54%;
    display: flex;
    align-items: center;
  }

  .class_fte{
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .adjust-component{
    width: 23%;
  }
}