.optimizerheader{
  z-index: 5061;
  background-color: #333E6B;
  width: 100%;
  height: 112px;
  padding-left: 15px;
  padding-right: 15px;
  color: #fff;
  
  .export_button{
    margin-bottom: 0 !important;
  }
  .left_column{
    height: 112px;
    text-align: center;
    display: flex;
    align-items: center;
    .title_container{
      text-align: left;

      .title{
        font-size: 24px;
        font-weight: bold;
      }
      .back_link{
        font-size: 18px;
        color: #fff !important;
      }
    }
  }
  .right_column{
    height: 112px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .row{
    margin: 0;
    padding: 0;
  }
}
