.optimizerboard{
  text-align: center;
  overflow-y: visible;

  .board{
    display: flex;
    justify-content: center;

    .center_container{
      width: 65vw !important;
      max-width: 65vw !important;
      min-width: 600px; 

      .rows{

        .centeritem{
          position: relative;
          justify-content: center;
          .schedulerow{
            width: 65vw !important;
            max-width: 65vw !important;
            min-width: 600px; 
            .row{
              width: 100%;
            }
          }
        }
      }
    }
  }

}