.adjust-component{
  width: 23% !important;
  min-width: 130px !important;
  font-weight: 800;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 10px;
  
  .content{
    max-width: 120px !important;
    min-width: 120px !important;
    width: 120px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .text{
    font-weight: 700;
    padding: 0px;
    text-align: left;
    color: #5AA9C7;
    font-size: 14px;
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0; 
  }

  .adjust{
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: none;
    max-width: 100px;
    text-align: right;
    background-color: #5AA9C7;
    color: white;
    font-weight: normal;
    font-size: 30px;
    font-family: myYantramanav;
    padding-right: 10px;
  }

  .spinner-button {
    user-select: none; 
    border: none;
    color: white;
    background-color: #5AA9C7;
    width: 20px;
    margin: 0px;
    height: 24px;
    line-height: 20px;
    padding: 0px;
    outline: none;
    margin-left: -1px;
    
  }

  .macOSarrows{
    font-size: 12px;
  }

  #inc-button {
    border-top-right-radius: 3px;
    text-align: right;
  }
  
  #dec-button {
    border-bottom-right-radius: 3px;
    text-align: right;
  }
}