.pdfheader{
  text-align: left;
  
  margin-top: 3vh;
  
  

  .your_school_title{
    color: #E16137;

    font-size: 1.4rem !important;
    font-weight: bold;
    padding-bottom: 5px;
  }

  .your_school_info_container{
    background-color: #FCF1EE;
    padding-top: 10px;
    padding-bottom: 10px;
    
  margin-bottom: 3vh;
    
    .text-align-center{
      text-align: center;
    }
    
    .big_number{
      font-size: 2.8rem !important;
      font-weight: bold;
    }

    .text_tag{
      transform: translateY(-0.6vh);
    }

    

    .with_border{
      border-right: 1px solid #E16137 !important;
    }

    

    .fix_padding{
      padding-left: 30px !important;
    }
    .your_school_info_row{
      margin: 0;
      padding: 0;
      color: #E16137;
      font-size: 1rem !important;
      font-family: myYantramanav;

      .your_school_info_box{
        height: 6vh !important;
        font-size: 150%;
        padding-left: 2vw;
        padding-right: 2vw;
      }

      .center_text{
        display: flex;
        justify-content: center;
      }
    }
  }

  .base_schedule_title{
    font-size: 1.5rem !important;
    font-weight: 450 !important;
    padding-left: 30px;
    padding-bottom: 5px;
  }

  .class_size_title{
    text-align: center;
    font-size: 1.5rem !important;
    font-weight: 450 !important;
  }

  .fte_title{
    text-align: center;
    font-size: 1.5rem !important;
    font-weight: 450 !important;
  }

  .line{
    border-bottom:1px solid #979797;
    width: 100%;
  }

  .w-48{
    width: 48% !important;
  }
  .w-22{
    width: 22% !important;
  }

  .w-26{
    width: 26% !important;
  }

  
}
