.teacher-student{
  width: 54%;
  height:97px;
  padding-left: 30px;
  padding-top: 20px;
  color: #333E6B;
  font-family: myYantramanav;
  font-weight: 500;
  

  
  .row-title{
    font-weight: 700;
    font-size: 18px;
    
  }


  .padding{
    padding: 1.5px;
  }

  .col{
    padding: 0px;
    font-size: 16px;
  }

  .box {
    border: 1px solid #645AAF;
    min-width: 9px;
    min-height: 9px;
  }

  .fill {
    background-color: #645AAF;
  }
  
  .font-italic {
    margin-left: 5px;
    width: max-content;
    font-family: myRaleway;
    font-size: 12px;
  }

  .element-padding{
    padding-right: 30px;
    padding-left: 40px;
  }

  

}
