.optimizer{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: visible;
  @media screen and (max-width: 600px) {
    overflow-x: scroll !important;
  }

  .line {
    border-top: 0.5px solid #A9A9A9;
    width: 100%;
    height: 1px;
    margin-bottom: 25px;
    margin-top: 70px;
  }

  .header{
    background-color: #F1F1F1;
    box-shadow: 0px 2px 4px #ddd;

    
  }

  .optimizer_page_text{
    text-align: left;
    padding-top: 10px;
    padding-left: 30px;
    color: #333E6B;
    font-size: 16px;
    font-family: myRaleway;
    font-weight: 450;
    max-width: 710px;
    padding-right: 20px;
  }

  .thankyou_window{
    .window{
      .buttons_container{
        .back_btn{
          border: none !important;
          border-color: #FFFFFF00 !important;
          border-width: 0px !important;
        }
      }
    }

  }

  .button_type_a{
    background-color: #5BAAC8;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    border-width: 1px;
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .button_clickable_text_a{
    font-size: 18px;
    color: #9B9B9B;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
  }


  .button_clickable_text_b{
    font-style: normal;
    font-weight: bold;
    color: #65AFCA;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
    font-family: myYantramanav;
  }

  .row{
    margin: 0;
    
  }

  .centered_form{
    transform: translateX(50%) translateX(- 92px);
    padding-bottom: 10px;
  }

  .dropdown_disabled{
    pointer-events: none;


    .functionalbutton{
      color: #999 !important;
    }

    
    .dropdown_form_entry{
        .label{
            .input_field{
                color: #999 !important;

            }
        }
        .label:after{
            border-color: #999 transparent transparent transparent !important;
        }
    }
  }

  .export_button{
    outline: none;
    font-size: 12px;
    width: 150px;
    height: 40px;
    background-color: #5aa9c7;
    margin-bottom: 50px !important;
    .img{
        filter: brightness(4);
    }     
  }
  
  .export_button:hover{
    cursor: pointer;
    background-color:#2e8eb3 !important;
  }

  

  .sticky-top {
    background-color: white;
  }
}